import "./App.css";
import Header from "./Components/Header/Header";
import Body from "./Components/Body/Body";
import { useEffect, useState } from "react";
import axios from "axios";
import Footer from "./Components/Footer/Footer";

function App() {
  const [websiteViews, setWebsiteViews] = useState("");
  const sendView = async () => {
    let res = await axios.get("users/website-viewings");
    console.log(res.data);
    let { website_views } = res.data;
    setWebsiteViews(website_views);
  };

  useEffect(() => {
    sendView();
  }, []);
  return (
    <div className="App">
      <Header />
      <Body />
      <Footer websiteViews={websiteViews} />
    </div>
  );
}

export default App;
