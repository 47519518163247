import React, { useState } from "react";
import "../../Styles/body.css";
// import Offer from "../Offers/Offer";

import van1 from "../../assets/van1.jpg";
import van2 from "../../assets/van2.jpg";
import van3 from "../../assets/van3.jpg";
import van4 from "../../assets/van4.jpg";
import van5 from "../../assets/van5.jpg";

const assets = [van1, van2, van3, van4, van5];

const offers = [
  "Singola corsa",
  "Personal driver",
  "Servizi per le aziende",
  "Viaggi per luoghi turistici o per eventi sportivi",
  "Spostamenti personali o di famiglia",
  "Accompagnamento a cene o cerimonie",
];

export default function Body() {
  var [slider, setSlider] = useState(0);

  const sliderRight = () => {
    if (slider < assets.length - 1) {
      slider++;
    } else {
      slider = 0;
    }
    setSlider(slider);
  };

  const sliderLeft = () => {
    if (slider > 0) {
      slider--;
    } else {
      slider = assets.length - 1;
    }
    setSlider(slider);
  };

  return (
    <div className="content">
      <div className="hero--wrapper">
        <img className="hero-img" src={require("../../assets/van2.jpg")} />

        <div className="hero-text">
          <h3 className="hero-h3">
            <span className="ws-hero-h3">NCC </span> GHIDONI EMANUELE
            <span className="ws-hero-h3">TAXI </span>SU PRENOTAZIONE
          </h3>
          <a
            className="hero-instagram--a"
            href="https://www.instagram.com/Ncc_ghidoniemanuele"
            target="_blank"
          >
            Seguimi su instagram
            <img
              className="hero-instagram"
              src={require("../../assets/instagram.png")}
            />
          </a>
          <a href="tel:+393484595599" className="booking-btn">
            Chiama ora
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=393484595599"
            target="_blank"
            className="quote-btn"
          >
            Scrivimi su WhatsApp
          </a>
        </div>
      </div>

      <div className="our-vehicles--wrapper">
        <h3>I nostri servizi</h3>
        <hr className="divider" />
        <div className="our-vehicles--p-img">
          <div className="card">Vettura con 7 posti passeggeri</div>
          <div className="card">
            Trasferimenti per fiere, eventi, matrimoni, servizio navetta,
            trasporto bagagli, congressi, sagre e tour turistici
          </div>
          <div className="card">
            Trasferimenti per tutti gli aeroporti, stazioni ferroviarie, porti e
            ospedali
          </div>
          <div className="card">
            Trasferimenti verso gli alberghi, agriturismi, B&B e case vacanze
          </div>
          <div className="slider-wrapper">
            <button className="slider-arrows" onClick={sliderLeft} />
            <img className="slider-img" src={assets[slider]} />
            <button className="slider-arrows" onClick={sliderRight} />
          </div>
        </div>
      </div>
      <div className="about-us--wrapper">
        <p className="about-us--p">
          Prenota il tuo transfer privato come e quando vuoi, disponibile 24 ore
          su 24 per tutte le destinazioni italiane e estere.
        </p>
      </div>
      {/* <div className="payment-methods--wrapper">
        <h3>Metodi di pagamento</h3>
        <hr className="divider" />
        <ul>
          <li>satispay</li>
          <li>contanti</li>
        </ul>
      </div> */}
      {/* <div className="how-to-book--wrapper"> */}
      <h3>Come prenotare</h3>
      <hr className="divider" />
      <p className="how-to-book--p">
        Scriveteci su WhatsApp o chiamateci al telefono, saremo lieti di darvi
        tutte le informazioni desiderate.
      </p>
      {/* </div> */}
    </div>
  );
}
