import React from "react";
// import "../../Styles/header.css";

export default function Header() {
  return (
    <div className="header">
      <img className="logo" src={require("../../assets/LogoFUERA2.png")}></img>
    </div>
  );
}
