import React from "react";
import "../../Styles/footer.css";

export default function Footer(props) {
  return (
    <div className="footer">
      visite: {props.websiteViews}
      <span className="made-by">
        made by
        <a
          className="ws-made-by"
          href="https://werewolf.solutions"
          target="_blank"
        >
          Werewolf solutions
        </a>
      </span>
    </div>
  );
}
